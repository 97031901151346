<template>
  <v-row>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="invitesList"
        item-key="full_name"
        :class="datatableOptions.class"
        :mobile-breakpoint="datatableOptions.mobileBreakpoint"
        :dense="datatableOptions.dense"
        :loading="loading"
        loading-text="Loading... Please wait"
        :show-select="showMultiSelect"
        :single-select="true"
        v-model="selectedInvites"
      >
        <!-- name -->
        <!-- <template #[`item.full_name`]="{ item }">
				<div class="d-flex flex-column">
					<span class="d-block font-weight-semibold text--primary text-truncate">{{ item.full_name }}</span>
					<small>{{ item.post }}</small>
				</div>
			</template>-->
        <!-- status -->
        <template #[`item.id`]="{ index }">
          <!-- <v-chip
            small
            :color="
              item.registrationStatus == 'Registered'
                ? 'success'
                : item.registrationStatus == 'Pending Registration'
                ? 'warning'
                : ''
            "
          >
            {{ item.registrationStatus }}
          </v-chip> -->
          {{ index + 1 }}
        </template>
        <template #[`item.vendor.name`]="{ item }">
          <span :title="`${item.vendor.companyName}: ${item.vendor.name}`">
            {{ item.vendor.companyName }}: {{ item.vendor.name }}
          </span>
        </template>
        <template #[`item.action`]="{ item }">
          <v-btn-toggle>
            <template v-if="hasComponentPermission('LM_UPDATEINVITESSTATUS') && !item.forVendor">
              <v-btn x-small v-on:click="changeReuqestStatus(item.id, true)">
                <v-icon color="success">{{ icons.mdiCheckboxMarkedCircleOutline }}</v-icon
            >&nbsp;
                Accept
              </v-btn>

              <v-btn x-small v-if="hasComponentPermission('LM_UPDATEINVITESSTATUS')"  v-on:click="changeReuqestStatus(item.id, false)">
                <v-icon color="error">{{ icons.mdiCloseCircleOutline }}</v-icon> &nbsp;
                Reject
              </v-btn>
            </template>
            <v-btn x-small v-if="hasComponentPermission('LM_DELETEINVITE') && item.forVendor" text v-on:click="cancelInvite(item.id)">
              Cancel
            </v-btn>
          </v-btn-toggle>
        </template>
        <!-- <template v-slot:footer>
          <v-row v-if="selectedInvites && selectedInvites.length">
            <v-col cols="12" style="margin:10px">
              <v-btn
                x-small
                color="success"
                :disabled="changingReuqestStatus"
                :loading="changingReuqestStatus"
                v-on:click="changeReuqestStatus(true)"
              >
                Accept Selected
              </v-btn>

              <v-btn
                x-small
                color="error"
                v-on:click="changeReuqestStatus(false)"
                :disabled="changingReuqestStatus"
                :loading="changingReuqestStatus"
              >
                Reject Selected
              </v-btn>
            </v-col>
          </v-row>
        </template> -->
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import Util from '@/utils/axios-util.js'
import store from '@/store' // your vuex store
import { mdiCheckboxMarkedCircleOutline, mdiCloseCircleOutline } from '@mdi/js'
import { ref, reactive } from '@vue/composition-api'

export default {
  components: {},
  setup() {
    const dataTableKey = ''

    return {
      icons: { mdiCheckboxMarkedCircleOutline, mdiCloseCircleOutline },
      store: store,
      dataTableKey,
      invitesList: [],
      selectedInvites: [],
      showMultiSelect: false,
      loading: false,
      headers: [
        { text: 'Sr. No', value: 'id' },
        { text: 'Vendor Company: Name', value: 'vendor.name' },
        { text: 'Customer Mobile No', value: 'mobileNo' },
        { text: 'Status', value: 'status' },
        { text: 'Action', value: 'action' },
      ],
      changingReuqestStatus: false,
    }
  },
  mounted() {
    let e = this
    e.getData()
  },
  methods: {
    updateDtKey: function() {
      let e = this
      e.dataTableKey = e.GenerateRandomKey(4)
      e.editComponent = ''
    },
    edit: function(id) {
      let e = this
      if (!id) {
        e.errorMessage('Invalid customer selected.')
        return false
      }
      e.editComponent = ''
      Util.Axios.get(`/network/customer/${id}/get-details`)
        .then(function(response) {
          if (response.data.succeeded) {
            e.editObj = response.data.data
            e.editComponent = 'AddCustomerForm'
            e.editComponentKey = e.GenerateRandomKey(5)
          } else e.errorMessage('Something went wrong!')
        })
        .catch(function(response) {})
        .finally(function(response) {})
    },
    changeReuqestStatus: function(id, isAccepted) {
      let e = this
      if (!id) {
        e.errorMessage('Invalid Invite selected.')
        return false
      }
      e.changingReuqestStatus = true
      Util.Axios.post(`/customer/invite/update-status`, {
        customerInviteIds: [id],
        status: isAccepted ? 'Accepted' : 'Rejected',
      })
        .then(function(response) {
          if (response.data.succeeded) {
            e.successMessage(`Invite ${isAccepted ? 'Accepted' : 'Rejected'} successfully.`)
            e.getData()
          } else e.errorMessage('Something went wrong!')
        })
        .catch(function(response) {})
        .finally(function(response) {
          e.changingReuqestStatus = false
        })
    },
    cancelInvite: function(ciID) {
      let e = this
      if (!ciID) {
        e.errorMessage('Invalid invite selected.')
        return false
      }
      e.changingReuqestStatus = true
      Util.Axios.post(`/customer/invite/cancel`, {
        customerInviteIds: [ciID],
      })
        .then(function(response) {
          if (response.data.succeeded) {
            e.getData()
          } else e.errorMessage('Something went wrong!')
        })
        .catch(function(response) {})
        .finally(function(response) {
          e.changingReuqestStatus = false
        })
    },
    getData: function() {
      let e = this
      e.loading = true
      Util.Axios.get('/customer/invite/get-paged-pending', { params: { Statuses: ['Invited'] } })
        .then(function(response) {
          e.invitesList = response.data.data.items
        })
        .catch(function(response) {})
        .finally(function(response) {
          e.loading = false
        })
    },
    async fetchData() {
      let e = this
      if (e.customFilters.auditTrailName) {
        const response = await Util.Axios.get(`/customer/get-active-paged`)
        //?&AuditableEntityCode=${e.customFilters.auditTrailName}
        e.ExcelDownload.data = response.data.data.items
        e.invitesList = response.data.data.items
        return e.ExcelDownload.data
      } else {
        e.errorMessage('Please select Entity to export Audit Trail.')
        return []
      }
    },
  },
}
</script>
