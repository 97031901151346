<template>
  <v-row>
    <v-col class="text-right" col="12">
      <v-btn color="primary" dark v-on:click="registrationDialog = true">
        <v-icon left>{{ icons.mdiPlus }}</v-icon
        >Add New
      </v-btn>
      <v-dialog v-model="registrationDialog" max-width="600px">
        <v-card>
          <v-card-title>Create Mandate (Send Registration Link)</v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form" v-model="isValid" lazy-validation>
                <v-row>
                  <v-col cols="12">
                    <label>Select Customer</label>
                    <v-select
                      v-model="sendRegistration.CustomerId"
                      :items="customersList"
                      item-text="value"
                      item-value="key"
                      placeholder="Select Customer"
                      dense
                      outlined
                      clearable
                      :rules="numberRules"
                    ></v-select>
                  </v-col>
                  <!-- <v-col cols="12">
                    <label> Mandate Expiry Date (IST)</label>
                    <v-menu v-model="expiryDatepopup" :close-on-content-click="false" transition="scale-transition">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          outlined
                          v-model="sendRegistration.MandateExpiryAt.DateTime"
                          readonly
                          dense
                          v-bind="attrs"
                          :rules="dateRules"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="sendRegistration.MandateExpiryAt.DateTime"
                        @input="expiryDatepopup = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12">
                    <label> Mandate Amount (INR)</label>
                    <v-text-field
                      v-model="sendRegistration.MandateAmount.Amount"
                      type="number"
                      outlined
                      dense
                      placeholder="E.g. 1000"
                      :rules="numberRules"
                    ></v-text-field>
                  </v-col> -->
                  <v-col cols="12">
                    <label>Select Mandate approval type</label>
                    <v-select
                      v-model="sendRegistration.mandateType"
                      :items="mandateTypes"
                      placeholder="Select Mandate approval type"
                      dense
                      outlined
                      clearable
                      :rules="stringRules"
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row v-if="sendRegistration.mandateType == 'Paper NACH'">
                  <v-col cols="12">
                    <label>Account Holder Name </label>
                    <v-text-field
                      v-model="sendRegistration.beneficiaryName"
                      outlined
                      dense
                      placeholder="E.g. Josh Hezalwood"
                      :rules="vbeneficiaryName"
                      hint="(Beneficiary name as per razor pay)"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <label>Account Number</label>
                    <v-text-field
                      v-model="sendRegistration.accountNumber"
                      outlined
                      dense
                      max-length="18"
                      min-length="8"
                      placeholder="E.g. 000000000000"
                      :rules="vaccountNumber"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <label>Select Account Type</label>
                    <v-select
                      v-model="sendRegistration.accountType"
                      :items="[
                        { text: 'Savings', value: 'savings' },
                        { text: 'Current', value: 'current' },
                        { text: 'Cash Credit', value: 'cc' },
                        { text: 'SB-NRE', value: 'nre' },
                        { text: 'SB-NRO', value: 'nro' },
                      ]"
                      placeholder="e.g Savings"
                      dense
                      outlined
                      clearable
                      :rules="vaccountType"
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <label>IFSC Code</label>
                    <v-text-field
                      v-model="sendRegistration.ifscCode"
                      outlined
                      dense
                      max-length="11"
                      placeholder="E.g. SBIN00000111"
                      :rules="vifscCode"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              @click="saveRegistrationDetails"
              :loading="saveResistrationBtnLoading"
              :disabled="saveResistrationBtnLoading"
            >
              Send Registration Link
            </v-btn>

            <v-btn
              color="light"
              @click="
                registrationDialog = false
                $emit('cancelled')
              "
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { mdiPlus, mdicalendar } from '@mdi/js'
import Util from '@/utils/axios-util.js'
import { config } from '@/constants/config.js'
import moment from 'moment'
let sendRegistrationObj = {
  CustomerId: '',
  // MandateExpiryAt: {
  //   DateTime: '',
  //   TimeZone: 'India Standard Time',
  // },
  // MandateAmount: {
  //   Amount: '',
  //   Currency: 'INR',
  // },
  mandateType: '',
  beneficiaryName: '',
  accountNumber: '',
  accountType: '',
  ifscCode: '',
}
export default {
  data() {
    return {
      isValid: false,
      registrationDialog: false,
      saveResistrationBtnLoading: false,
      stringRules: [v => !!v || 'This field is required.'],
      numberRules: [v => (!!v && v != 0) || 'This field is required.'],
      dateRules: [v => !!v || 'Expiry date is required.'],
      vbeneficiaryName: [
        v => (this.sendRegistration.mandateType == 'Paper NACH' && !!v) || 'Please enter Account Holder Name.',
      ],
      vaccountNumber: [
        v => (this.sendRegistration.mandateType == 'Paper NACH' && !!v) || 'Account Number is required.',
      ],
      vaccountType: [v => (this.sendRegistration.mandateType == 'Paper NACH' && !!v) || 'Please select account type'],
      vifscCode: [v => (this.sendRegistration.mandateType == 'Paper NACH' && !!v) || 'Please enter IFSC code.'],
      expiryDatepopup: false,
      customersList: [],
      mandateTypes: [
        'Card',
        'Netbanking',
        'Paper NACH',
        // 'Aadhaar'
      ],
      registrationDialog: false,
      saveResistrationBtnLoading: false,
      sendRegistration: sendRegistrationObj,
      icons: {
        mdiPlus,
        mdicalendar,
      },
    }
  },
  setup() {
    return {}
  },
  props: {
    obj: {
      default() {
        return {}
      },
      type: Object,
    },
    showDialogOnLoad: {
      default() {
        return false
      },
      type: Boolean,
    },
  },
  mounted() {
    let e = this
    e.getCustomers()
    if (e.obj != {}) {
      e.sendRegistration = Object.assign({}, e.obj)
      e.registrationDialog = e.showDialogOnLoad
    }
  },
  methods: {
    saveRegistrationDetails: function() {
      let e = this
      let isValidated = e.$refs.form.validate()
      if (isValidated) {
        e.saveResistrationBtnLoading = true

        Util.Axios.post(
          `/customer/${e.sendRegistration.CustomerId}/generate-mandate-registration-link`,
          e.sendRegistration,
        )
          .then(function(response) {
            if (response.data.succeeded) {
              e.successMessage('Registration Link sent successfully.')
              e.$emit('mandateAdded')
              e.registrationDialog = false
            } else {
              e.errorMessage(e.generateErrorMessage(response.errors))
            }
          })
          .catch(function(response) {})
          .finally(function(response) {
            e.saveResistrationBtnLoading = false
          })
      }
    },
    getCustomers: function() {
      let e = this
      Util.Axios.get('/customer/get-customers-ddl')
        .then(function(response) {
          e.customersList = response.data.data.items
        })
        .catch(function(response) {})
        .finally(function(response) {})
    },
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.sendRegistration.MandateExpiryAt
        ? moment(this.sendRegistration.MandateExpiryAt).format(config.dateFormat)
        : ''
    },
  },
}
</script>
