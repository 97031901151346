<template>
  <v-card>
    <v-app-bar flat>
      <v-toolbar-title class="text-h6 pl-0"> Customers </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-if="customersList && customersList.length" title="Export">
            <v-list-item-title>
              <download-excel v-if="customersList && customersList.length" :key="ExcelDownload.key"
                class="btn btn-primary" :data="customersList" :before-generate="startDownload"
                :before-finish="finishDownload" :fields="ExcelDownload.fields" :name="`tenweeks_customers_export.xls`">
                <v-icon>{{ icons.mdiFileExportOutline }} </v-icon>
                Download Excel
              </download-excel>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-data-table :headers="headers" :items="customersList" item-key="full_name" :loading="loading"
      :loading-text="datatableOptions.loadingText" :class="datatableOptions.class"
      :mobile-breakpoint="datatableOptions.mobileBreakpoint" :dense="datatableOptions.dense">
      <!-- name -->
      <!-- <template #[`item.full_name`]="{ item }">
				<div class="d-flex flex-column">
					<span class="d-block font-weight-semibold text--primary text-truncate">{{ item.full_name }}</span>
					<small>{{ item.post }}</small>
				</div>
			</template>
			<template #[`item.transaction_no`]="{ item }">
				{{ item.transaction_no }} / {{ item.total_transaction }}
			</template> -->
      <!-- status -->
      <template #[`item.registrationStatus`]="{ item }">
        <v-chip small :color="
          item.registrationStatus == 'Registered'
            ? 'success'
            : item.registrationStatus == 'Pending Registration'
              ? 'warning'
              : ''
        ">
          {{ item.registrationStatus }}
        </v-chip>
      </template>
      <template #[`item.action`]="{ item }">
        <v-btn-toggle>
          <v-btn x-small v-on:click="editCustomer(item.id)">
            <v-icon>{{ icons.mdiCircleEditOutline }}</v-icon>
          </v-btn>
          <v-btn x-small v-on:click="deleteCustomerConfirmation(item.id)">
            <v-icon>{{ icons.mdiDeleteCircleOutline }}</v-icon>
          </v-btn>
          <v-btn color="primary" x-small outlined title="Show Mandates"
            :to="{ name: 'mandates', params: { customerID: item.id } }">
            <v-icon>{{ icons.mdiInformationOutline }}</v-icon>
          </v-btn>

          <v-btn color="primary" x-small outlined title="Register as a Vendor"
            v-on:click="registerVendorConfirmation(item.id, item.companyName)">Register as a Vendor
          </v-btn>
          <!-- <v-btn
						color="primary"
						x-small
						outlined
						title="Send Registration Link"
						v-on:click="sendRegistrationLink(item.id)"
						v-if="item.activeMandate == null"
					>
						<v-icon>{{ icons.mdiSend }}</v-icon>
					</v-btn> -->
        </v-btn-toggle>
      </template>
    </v-data-table>
    <v-dialog v-model="registrationDialog" max-width="350">
      <v-card>
        <v-card-title>Send Registration Link</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <label> Mandate Expiry Date (IST)</label>
                <v-menu v-model="expiryDatepopup" :close-on-content-click="false" transition="scale-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field outlined v-model="sendRegistration.MandateExpiryAt.DateTime" readonly dense
                      v-bind="attrs" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="sendRegistration.MandateExpiryAt.DateTime" @input="expiryDatepopup = false">
                  </v-date-picker>
                </v-menu>

                <label> Mandate Amount (INR)</label>
                <v-text-field v-model="sendRegistration.MandateAmount.Amount" type="number" outlined dense
                  placeholder="E.g. 1000"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary" @click="saveRegistrationDetails" :loading="saveResistrationBtnLoading"
            :disabled="saveResistrationBtnLoading">
            Save
          </v-btn>

          <v-btn color="light" @click="registrationDialog = false"> Cancel </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deleteDialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Are you sure? </v-card-title>

        <v-card-text>
          Do you want to delete customer <b>{{ deleteObj.name }}</b> from company <b>{{ deleteObj.companyName }}</b>.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn @click="deleteDialog = false"> Cancel </v-btn>

          <v-btn color="error" :loading="deleteLoading" :disabled="deleteLoading" v-on:click="deleteCustomer">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="mandateDetailsDialog" max-width="350">
      <v-card v-if="mandateDetails && mandateDetails.status">
        <v-card-title>Mandate Details</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <p>
                  Mandate Status: <b>{{ mandateDetails.status }}</b>
                </p>
                <label> Mandate Expiry Date (UTC)</label>
                <v-text-field outlined v-model="mandateDetails.expiresAt" readonly dense v-bind="attrs" v-on="on">
                </v-text-field>
                <label> Mandate Amount (INR)</label>
                <v-text-field v-model="mandateDetails.maxAmount.amount" type="number" outlined dense readonly>
                </v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="light" @click="mandateDetailsDialog = false">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      <v-snackbar v-model="showRegisterVendorConfirmation" vertical :timeout="10000">
        Are You sure, Do you set company: {{ registerVendorData.company }} as a Vendor.

        <template v-slot:action="{ attrs }">
          <v-btn
            color="success"
            @click="registerAsVendor"
            >Yes, Sure
          </v-btn>
          <v-btn text v-bind="attrs" @click="showRegisterVendorConfirmation = false">
            Cancel
          </v-btn>
        </template>
      </v-snackbar>
  </v-card>
</template>

<script>
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiCircleEditOutline,
  mdiDeleteCircleOutline,
  mdiSend,
  mdiInformationOutline,
  mdiFileExportOutline,
  mdiLoading,
} from '@mdi/js'
import Util from '@/utils/axios-util.js'
import { ref, reactive } from '@vue/composition-api'

export default {
  setup() {
    const loading = ref(false)
    const registrationDialog = ref(false)
    const expiryDatepopup = ref(false)
    const customersList = ref([])
    const sendRegistration = reactive({
      MandateExpiryAt: {
        DateTime: '',
        TimeZone: 'India Standard Time',
      },
      MandateAmount: {
        Amount: '',
        Currency: 'INR',
      },
    })
    const saveResistrationBtnLoading = ref(false)
    const deleteObj = reactive({})
    const deleteDialog = ref(false)
    const deleteLoading = ref(false)
    const ExcelDownload = reactive({
      data: [],
      loading: false,
      columnNames: {},
      key: 'asdkpaodks',
      fields: {
        Company: 'companyName',
        'Contact Person': 'name',
        Email: 'email',
        'Contact No': 'mobileNo',
        // Action: {
        // 	field: 'type',
        // 	callback: value => {
        // 		return this.$options.filters.AuditType(value)
        // 	},
        // },
        Status: 'registrationStatus',
      },
    })

    return {
      headers: [
        { text: 'Company', value: 'companyName' },
        { text: 'Contact Person', value: 'name' },
        { text: 'Email', value: 'email' },
        { text: 'Contact No', value: 'mobileNo' },
        //{ text: 'Amount', value: 'amount' },
        // { text: 'Mandate', value: 'activeMandate' },
        { text: 'Mandate Status', value: 'registrationStatus' },
        { text: '', value: 'action' },
      ],
      customersList,
      loading,
      ExcelDownload,
      // icons
      icons: {
        mdiDotsVertical,
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiCircleEditOutline,
        mdiDeleteCircleOutline,
        mdiSend,
        mdiInformationOutline,
        mdiFileExportOutline,
      },
      sendRegistration,
      registrationDialog,
      expiryDatepopup,
      saveResistrationBtnLoading,
      deleteObj,
      deleteDialog,
      deleteLoading,
      showRegisterVendorConfirmation: false,
      registerVendorData: {}
    }
  },
  data() {
    return {
      mandateDetailsDialog: false,
      mandateDetailsDialogkey: 'asdaosdjo',
      mandateDetails: {},
    }
  },
  mounted() {
    let e = this
    e.getData()
  },
  methods: {
    getData: function () {
      let e = this
      e.loading = true
      Util.Axios.get('/customer/get-paged-customers')
        .then(function (response) {
          e.customersList = response.data.data.items
        })
        .catch(function (response) { })
        .finally(function (response) {
          e.loading = false
        })
    },
    sendRegistrationLink: function (id) {
      let e = this
      e.sendRegistration = {
        CustomerId: id,
        MandateExpiryAt: {
          DateTime: '',
          TimeZone: 'India Standard Time',
        },
        MandateAmount: {
          Amount: '',
          Currency: 'INR',
        },
      }
      e.registrationDialog = true
    },
    saveRegistrationDetails: function () {
      let e = this
      if (!e.sendRegistration.CustomerId) {
        e.errorMessage('Invalid customer id.')
        return false
      }
      if (!e.sendRegistration.MandateExpiryAt.DateTime) {
        e.errorMessage('Please select mandate expiry date.')
        return false
      }
      if (!e.sendRegistration.MandateAmount.Amount) {
        e.errorMessage('Please select mandate amount.')
        return false
      }
      e.saveResistrationBtnLoading = true
      Util.Axios.post(`/customer/${e.sendRegistration.CustomerId}/send-registration-link`, e.sendRegistration)
        .then(function (response) {
          if (response.data.succeeded) {
            e.successMessage('Registration Link sent successfully.')
            e.getData()
            e.registrationDialog = false
          } else {
            e.errorMessage(e.generateErrorMessage(response.errors))
          }
        })
        .catch(function (response) { })
        .finally(function (response) {
          e.saveResistrationBtnLoading = false
        })
    },
    deleteCustomerConfirmation: function (id) {
      let e = this
      if (id) {
        e.deleteObj = e.customersList.filter(p => p.id == id)[0]
        e.deleteDialog = true
      } else {
        e.errorMessage('Invalid customer selected to delete')
      }
    },
    deleteCustomer: function () {
      let e = this
      if (e.deleteObj.id) {
        e.deleteLoading = true
        Util.Axios.delete(`/customer/${e.deleteObj.id}/delete`)
          .then(function (response) {
            if (response.data.succeeded) {
              e.successMessage('Customer Deleted successfully.')
              e.getData()
              e.deleteDialog = false
            } else {
              e.errorMessage(e.generateErrorMessage(response.data.errors))
            }
          })
          .catch(function (response) { })
          .finally(function (response) {
            e.deleteLoading = false
          })
      } else {
        e.errorMessage('Invalid customer selected.')
      }
    },
    editCustomer: function (id) {
      this.$emit('editCustomer', id)
    },
    showMandateDetails: function (id) {
      let e = this
      if (id) {
        e.mandateDetails = e.customersList.filter(p => p.id == id)[0].activeMandate
        e.mandateDetailsDialogkey = e.GenerateRandomKey(5)
        e.mandateDetailsDialog = true
      } else {
        e.errorMessage('Invalid customer selected.')
      }
    },
    startDownload() {
      let e = this
      e.ExcelDownload.loading = true
    },
    finishDownload() {
      let e = this
      e.ExcelDownload.loading = false
    },
    registerVendorConfirmation(id,company) { 
      let e = this;
      e.registerVendorData={
        id,
        company
      }
      e.showRegisterVendorConfirmation = true;

    },
    registerAsVendor(){
      let e = this;
       e.loading = true

       if(!e.registerVendorData.id){
        e.errorMessage('Invalid customer selected.')
        return false;
       }
      Util.Axios.post(`/customer/register-as-vendor`, {
        tenantCustomers:[
          {customerId:parseInt(e.registerVendorData.id)}
        ]
      })
        .then(function (response) {
          if (response.data.succeeded) {
            e.successMessage(`Company is registered as Vendor successfully.`)
            e.getData()
          } else {
            e.errorMessage(e.generateErrorMessage(response.errors))
          }
        })
        .catch(function (response) { })
        .finally(function (response) {
          e.registerVendorData={};
            e.RegisterVendorConfirmation = false
          e.loading = false
        })
    }
  },
}
</script>
