var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{class:_vm.datatableOptions.class,attrs:{"headers":_vm.headers,"items":_vm.customersList,"item-key":"full_name","loading":_vm.loading,"loading-text":_vm.datatableOptions.loadingText,"mobile-breakpoint":_vm.datatableOptions.mobileBreakpoint,"dense":_vm.datatableOptions.dense},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"truncateSpan text--primary",attrs:{"title":item.name},on:{"click":function($event){return _vm.showDetails(item.id)}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.openAmount.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("format_money")(item.openAmount.amount,item.openAmount.currency))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"x-small":"","disabled":!item.mandateRequired},on:{"click":function($event){return _vm.CreateMandate(item.id)}}},[_vm._v(" Register Mandate ")])]}}],null,true)}),(_vm.loadComponent)?_c(_vm.loadComponent,{tag:"component",attrs:{"showDialogOnLoad":true,"obj":_vm.sendRegistration},on:{"mandateAdded":_vm.getData,"cancelled":function($event){_vm.loadComponent=''}}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.showDetailsDialog),callback:function ($$v) {_vm.showDetailsDialog=$$v},expression:"showDetailsDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Customer Details ")]),_c('v-divider'),(_vm.showDetailsDialog && _vm.showDetailsData)?_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_vm._v("Customer Name: "),_c('b',[_vm._v(" "+_vm._s(_vm.showDetailsData.companyName))])]),_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" Customer Registration Status:"),_c('b',[_vm._v(" "+_vm._s(_vm.showDetailsData.registrationStatus))])]),_c('v-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v("Customer Representative Details")])]),_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" Name:"),_c('b',[_vm._v(" "+_vm._s(_vm.showDetailsData.name))])]),_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" Email:"),_c('b',[_vm._v(" "+_vm._s(_vm.showDetailsData.email))])]),_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" Mobile No:"),_c('b',[_vm._v(" "+_vm._s(_vm.showDetailsData.mobileNo))])])],1)],1):_vm._e(),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.showDetailsDialog = false}}},[_vm._v(" Ok ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }