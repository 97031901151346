<template>
  <v-row>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="customersList"
        item-key="full_name"
        :loading="loading"
        :loading-text="datatableOptions.loadingText"
        :class="datatableOptions.class"
        :mobile-breakpoint="datatableOptions.mobileBreakpoint"
        :dense="datatableOptions.dense"
      >
        <!-- name -->
        <!-- <template #[`item.full_name`]="{ item }">
				<div class="d-flex flex-column">
					<span class="d-block font-weight-semibold text--primary text-truncate">{{ item.full_name }}</span>
					<small>{{ item.post }}</small>
				</div>
			</template>-->
        <!-- status -->
        <template #[`item.id`]="{ index }">
          <!-- <v-chip
            small
            :color="
              item.registrationStatus == 'Registered'
                ? 'success'
                : item.registrationStatus == 'Pending Registration'
                ? 'warning'
                : ''
            "
          >
            {{ item.registrationStatus }}
          </v-chip> -->
          {{ index + 1 }}
        </template>
        <template #[`item.name`]="{ item }">
            <span class="truncateSpan text--primary" v-on:click="showDetails(item.id)" :title=" item.name ">
              {{ item.name }}
            </span>
        </template>
        <template #[`item.openAmount.amount`]="{ item }">
          {{ item.openAmount.amount | format_money(item.openAmount.currency) }}
        </template>
        <template #[`item.action`]="{ item }">
          <v-btn x-small v-on:click="CreateMandate(item.id)" :disabled="!item.mandateRequired">
            Register Mandate
          </v-btn>
        </template>
      </v-data-table>
      <component 
        v-if="loadComponent" 
        v-bind:is="loadComponent" 
        :showDialogOnLoad="true" 
        :obj="sendRegistration"
        @mandateAdded="getData" 
        @cancelled="loadComponent=''"/>
    </v-col>
    <v-col cols="12">
      <v-dialog v-model="showDetailsDialog" max-width="500">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Customer Details
          </v-card-title>
          <v-divider></v-divider>

          <v-card-text v-if="showDetailsDialog && showDetailsData">
            <v-row>
              <v-col cols="12"
                >Customer Name: <b> {{ showDetailsData.companyName }}</b>
              </v-col>
              <v-col cols="12">
                Customer Registration Status:<b> {{ showDetailsData.registrationStatus }}</b>
              </v-col>
              <v-col cols="12">
                <h3>Customer Representative Details</h3>
              </v-col>
              <v-col cols="12">
                Name:<b> {{ showDetailsData.name }}</b>
              </v-col>
              <v-col cols="12">
                Email:<b> {{ showDetailsData.email }}</b>
              </v-col>
              <v-col cols="12">
                Mobile No:<b> {{ showDetailsData.mobileNo }}</b>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="showDetailsDialog = false">
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import Util from '@/utils/axios-util.js'
import AddMandateForm from '@/views/mandates/AddMandateFormNew'

    const sendRegistrationObj = {
      CustomerId: '',
      // MandateExpiryAt: {
      //   DateTime: '',
      //   TimeZone: 'India Standard Time',
      // },
      // MandateAmount: {
      //   Amount: '',
      //   Currency: 'INR',
      // },
      mandateType: '',
      beneficiaryName: '',
      accountNumber: '',
      accountType: '',
      ifscCode: '',
    }
export default {
  components: {AddMandateForm},
  setup() {
    const dataTableKey = ''
    return {
      dataTableKey,
      sendRegistration:Object.assign({},sendRegistrationObj),
      customersList: [],
      loading: true,
      headers: [
        { text: 'Sr. No', value: 'id' },
        { text: 'Customer Name', value: 'name' },
        { text: 'Balance Amount', value: 'openAmount.amount',class:'text-center', cellClass:'text-end' },
        { text: 'Action', value: 'action' },
      ],
      showDetailsDialog: false,
      showDetailsData: {},
      loadComponent:''
    }
  },
  mounted() {
    let e = this
    e.getData()
  },
  methods: {
    showDetails(id) {
      let e = this
      e.showDetailsData = {}
      Util.Axios.get(`/customer/${id}/get-details`)
        .then(function(response) {
          e.showDetailsData = response.data.data
          e.showDetailsDialog = true
        })
        .catch(function(response) {})
        .finally(function(response) {
          e.loading = false
        })
    },
    updateDtKey: function() {
      let e = this
      e.dataTableKey = e.GenerateRandomKey(4)
      e.editComponent = ''
    },
    edit: function(id) {
      let e = this
      if (!id) {
        e.errorMessage('Invalid customer selected.')
        return false
      }
      e.editComponent = ''
      Util.Axios.get(`/network/customer/${id}/get-details`)
        .then(function(response) {
          if (response.data.succeeded) {
            e.editObj = response.data.data
            e.editComponent = 'AddCustomerForm'
            e.editComponentKey = e.GenerateRandomKey(5)
          } else e.errorMessage('Something went wrong!')
        })
        .catch(function(response) {})
        .finally(function(response) {})
    },
    getData: function() {
      let e = this
      e.loading = true
      e.loadComponent = '';
      Util.Axios.get('/network/customer/get-active-paged')
        .then(function(response) {
          e.customersList = response.data.data.items
        })
        .catch(function(response) {})
        .finally(function(response) {
          e.loading = false
        })
    },
    async fetchData() {
      let e = this
      if (e.customFilters.auditTrailName) {
        const response = await Util.Axios.get(`/customer/get-active-paged`)
        //?&AuditableEntityCode=${e.customFilters.auditTrailName}
        e.ExcelDownload.data = response.data.data.items
        e.customersList = response.data.data.items
        return e.ExcelDownload.data
      } else {
        e.errorMessage('Please select Entity to export Audit Trail.')
        return []
      }
    },
    CreateMandate: function(customerId) {
      let e = this
      if (!customerId) {
        e.errorMessage('Invalid Customer Selected.')
        return false;
      }
      e.sendRegistration.CustomerId = customerId;
      e.loadComponent = 'AddMandateForm';
    },
  },
}
</script>
