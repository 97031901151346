<template>
  <v-row no-gutter>

      <v-spacer></v-spacer>
       <v-col md="7" lg="7" xl="7" sm="12">
      <InviteCustomer v-if="hasComponentPermission('LM_INVITECLIENT')" @customerDetailsChanged="updateDtKey" />
    </v-col>  
    <v-col md="2" lg="2" xl="2" sm="12"  v-if="hasComponentPermission('LM_ADDCUSTOMER')">
      <AddCustomerForm  @customerDetailsChanged="updateDtKey" />
    </v-col> 
    <v-col :cols="12" v-if="store.state.user.currentUser.isAdmin">
      <Datatable-Admin  :key="dataTableKey" @editCustomer="edit">
      </Datatable-Admin>
    </v-col>
    <v-col :cols="12" v-if="store.state.user.currentUser.isVendor || store.state.user.currentUser.isClient">
      <v-tabs v-model="activeTab" grow :next-icon="icons.mdiChevronRight" :prev-icon="icons.mdiChevronLeft" show-arrows>
        <v-tab v-if="hasComponentPermission('LM_READACTIVECLIENTS')">Active Customers</v-tab>
        <v-tab>Pending Invite</v-tab>
      </v-tabs>
      <v-tabs-items v-model="activeTab">
        <v-tab-item v-if="hasComponentPermission('LM_READACTIVECLIENTS')">
          <v-card flat>
            <Active-Customers></Active-Customers>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <Pending-Invites></Pending-Invites>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
    <v-col :cols="12" v-if="hasComponentPermission('LM_READACTIVEVENDORS')">
      <v-tabs
        grow
        v-model="activeVendorTab"
        :next-icon="icons.mdiChevronRight"
        :prev-icon="icons.mdiChevronLeft"
        show-arrows
      >
        <v-tab>Active Vendors</v-tab>
      </v-tabs>
      <v-tabs-items v-model="activeVendorTab">
        <v-tab-item>
          <Active-Vendors></Active-Vendors>
        </v-tab-item>
      </v-tabs-items>
    </v-col>

    <component
      v-bind:is="editComponent"
      @customerDetailsChanged="updateDtKey"
      v-if="editComponent && editObj != {}"
      :customerObj="editObj"
    ></component>

    <v-dialog
      v-model="detailsDialog"
      width="auto"
      style="overflow-y: inherit;"
      :fullscreen="$vuetify.breakpoint.xsOnly"
    >
      <v-card>
        <v-card-title>Customer Details</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="10">
                <h4>Company Representatives</h4>
              </v-col>
              <v-col cols="10">
                <v-btn color="primary" class="float-right" @click.stop="showInviteDialog">
                  <v-icon left>{{ icons.mdiPlus }}</v-icon>
                  Add Representative
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      <v-card>
        <v-card-text>
          <AddCustomerForm @customerDetailsChanged="newCustomerAdded" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Util from '@/utils/axios-util.js'
import DatatableAdmin from './Datatable-admin'
import ActiveCustomers from './Active-customers'
import ActiveVendors from './Active-vendors'
import PendingInvites from './Pending-Invites'
import AddCustomerForm from './AddCustomerForm'
import InviteCustomer from './InviteCustomer'
import { mdiChevronLeft, mdiChevronRight } from '@mdi/js'
import store from '@/store' // your vuex store

export default {
  components: {
    'Datatable-Admin': DatatableAdmin,
    AddCustomerForm,
    'Active-Customers': ActiveCustomers,
    'Active-Vendors': ActiveVendors,
    'Pending-Invites': PendingInvites,
    InviteCustomer,
  },
  setup() {
    const dataTableKey = ''
    const editObj = {}
    const editComponentKey = ''

    return {
      store: store,
      dataTableKey,
      editObj,
      editComponent: '',
      editComponentKey: '',
      activeTab: null,
      activeVendorTab: null,
      detailsDialog: false,
      icons: {
        mdiChevronLeft,
        mdiChevronRight,
      },
    }
  },
  methods: {
    updateDtKey: function() {
      let e = this
      e.dataTableKey = e.GenerateRandomKey(4)
      e.editComponent = ''
    },
    edit: function(id) {
      let e = this
      if (!id) {
        e.errorMessage('Invalid customer selected.')
        return false
      }
      e.editComponent = ''
      Util.Axios.get(`/customer/${id}/get-details`)
        .then(function(response) {
          if (response.data.succeeded) {
            e.editObj = response.data.data
            e.editComponent = 'AddCustomerForm'
            e.editComponentKey = e.GenerateRandomKey(5)
          } else e.errorMessage('Something went wrong!')
        })
        .catch(function(response) {})
        .finally(function(response) {})
    },
    async fetchData() {
      let e = this
      if (e.customFilters.auditTrailName) {
        const response = await Util.Axios.get(
          `/Log/get-paged-audittrails?&AuditableEntityCode=${e.customFilters.auditTrailName}`,
        )
        e.ExcelDownload.data = response.data.data.items
        return e.ExcelDownload.data
      } else {
        e.errorMessage('Please select Entity to export Audit Trail.')
        return []
      }
    },
    newCustomerAdded: function() {},
  },
}
</script>
