<template>
  <v-row>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="vendorsList"
        item-key="full_name"
        :loading="loading"
        :loading-text="datatableOptions.loadingText"
        :class="datatableOptions.class"
        :mobile-breakpoint="datatableOptions.mobileBreakpoint"
        :dense="datatableOptions.dense"
      >
        <!-- name -->
        <!-- <template #[`item.full_name`]="{ item }">
				<div class="d-flex flex-column">
					<span class="d-block font-weight-semibold text--primary text-truncate">{{ item.full_name }}</span>
					<small>{{ item.post }}</small>
				</div>
			</template>-->
        <!-- status -->
        <template #[`item.id`]="{ index }">
          {{ index + 1 }}
        </template>
        <template #[`item.name`]="{ item }">
            <span class="truncateSpan" :title=" item.name ">
              {{ item.name }}
            </span>
        </template>
        <template #[`item.openAmount.amount`]="{ item }">
          {{ item.openAmount.amount | format_money(item.openAmount.currency) }}
        </template>
        <!-- <template #[`item.action`]="{ item }">
        <v-btn-toggle>
          <v-btn x-small v-on:click="editCustomer(item.id)">
            <v-icon>{{ icons.mdiCircleEditOutline }}</v-icon>
          </v-btn>
        </v-btn-toggle>
      </template> -->
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import Util from '@/utils/axios-util.js'

export default {
  components: {},
  setup() {
    const dataTableKey = ''

    return {
      dataTableKey,
      vendorsList: [],
      loading: false,
      headers: [
        { text: 'Sr. No', value: 'id' },
        { text: 'Vendor Name', value: 'name' },
        { text: 'Balance Amount', value: 'openAmount.amount',class:'text-center', cellClass:'text-end' },
        { text: 'Action', value: 'action' },
      ],
    }
  },
  mounted() {
    let e = this
    if(e.hasComponentPermission('LM_READACTIVEVENDORS'))
    e.getData()
  },
  methods: {
    getData: function() {
      let e = this
      e.loading = true
      Util.Axios.get('/network/vendor/get-active-paged')
        .then(function(response) {
          e.vendorsList = response.data.data.items
        })
        .catch(function(response) {})
        .finally(function(response) {
          e.loading = false
        })
    },
  },
}
</script>
