<template>
  <div>
    <v-btn color="primary" class="float-right" @click.stop="showInviteDialog">
      <v-icon left>{{ icons.mdiPlus }}</v-icon>
      Invite Customer
    </v-btn>
    <v-bottom-sheet v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>Invite Customer</v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="isValid" lazy-validation>
            <v-row>
              <v-col cols="12">
                <label>Select Vendor</label>
                <v-select :items="vendorsDDL" item-text="value" item-value="key" outlined dense
                  v-model="sendInvite.vendorId"></v-select>
                <!-- :rules="numberRules" -->
              </v-col>

              <v-col cols="12">
                <label>Customer Mobile No</label>
                <v-text-field v-model="sendInvite.mobileNo" type="number" outlined dense placeholder="E.g. 1231231231"
                  :rules="mobileNoRules"></v-text-field>
              </v-col>
              <v-col offset-md="3" cols="12">
                <v-btn color="primary" v-on:click="saveCustomer" :loading="loading" :disabled="loading">
                  Save
                </v-btn>
                &nbsp;&nbsp;
                <v-btn color="light" @click="dialog = false">Cancel</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text style="margin-top:10px">
          <AddCustomerForm v-if="hasComponentPermission('LM_ADDCUSTOMER')" @customerDetailsChanged="newCustomerAdded" />
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { mdiPlus } from '@mdi/js'
import { ref, reactive } from '@vue/composition-api'
import AddCustomerForm from './AddCustomerForm'
import Util from '@/utils/axios-util.js'

export default {
  setup() {
    const sendInvite = reactive({ vendorId: '', mobileNo: '', countryCode: '+91' })
    const dialog = ref(false)
    const isValid = ref(false)
    const loading = ref(false)

    return {
      dialog,
      sendInvite,
      sendInviteObj: { vendorId: '', mobileNo: '', countryCode: '+91' },
      isValid,
      icons: {
        mdiPlus,
      },
      loading,
      numberRules: [v => !!v || 'Value is required.'],
      mobileNoRules: [v => !!v || 'Mobile No is required.', v => /^\d{10}$/.test(v) || 'Please enter valid mobile no.'],
      vendorsDDL: [],
    }
  },
  mounted() {
    let e = this
    e.getVendorDDL()
  },
  methods: {
    showInviteDialog: function () {
      let e = this
      e.sendInvite = Object.assign({}, e.sendInviteObj)
      e.dialog = true
    },
    saveCustomer: function () {
      let e = this
      let isValidated = e.$refs.form.validate()
      if (isValidated) {
        e.loading = true
        Util.Axios.post(`/customer/invite/create`, e.sendInvite)
          .then(function (response) {
            if (response.data.succeeded) {
              e.successMessage('Invite sent successfully.')
              e.dialog = false
              e.sendInvite = Object.assign({}, e.sendInviteObj)
            } else {
              e.errorMessage(e.generateErrorMessage(response.errors))
            }
          })
          .catch(function (response) { })
          .finally(function (response) {
            e.loading = false
          })
      }
    },
    getVendorDDL: function () {
      let e = this
      e.loading = true
      Util.Axios.get('/network/vendor/get-ddl',{params:{self:true}})
        .then(function (response) {
          e.vendorsDDL = response.data.data.items;
          if(e.vendorsDDL && e.vendorsDDL.length && e.vendorsDDL.length==1){
            e.sendInviteObj.vendorId = e.vendorsDDL[0].key
          }
        })
        .catch(function (response) { })
        .finally(function (response) {
          e.loading = false
        })
    },
    newCustomerAdded: function () {
      let e = this
      e.getVendorDDL()
    },
  },
  components: { AddCustomerForm },
}
</script>
